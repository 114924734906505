const apiCreate = (data) => {
  const endpoint = process.env.GATSBY_FEEDBACKBONE_ENDPOINT_URL;

  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

const apiUpdate = (id, data) => {
  const endpoint = process.env.GATSBY_FEEDBACKBONE_ENDPOINT_URL;

  fetch(`${endpoint}${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export { apiCreate, apiUpdate };
