import React, { useRef } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import {
  Row,
  StyledButtonText,
  StyledButton,
  Col, StyledSectionHeader,
} from '../../components/Layout';
import MerchantLayout from '../../components/Layout/MerchantLayout';
import KarlaTextArea from '../../components/Layout/KarlaTextArea';
import { apiUpdate } from '../../services/sendToApi';

function Success({ location }) {
  const { t } = useTranslation();
  const { state = {} } = location;
  const { id, merchant } = state;

  const inputRef = useRef();

  const submitFeedback = () => {
    const comments = inputRef.current.value;

    apiUpdate(id, { comments });
    navigate('/merchant/sent', { state: { comments, ...state } });
  };

  return (
    <MerchantLayout merchant={merchant}>
      <Col>
        <Row>
          <StyledSectionHeader>
            {t('merchant.whatswrong.title')}
          </StyledSectionHeader>
        </Row>

        <Row>
          <KarlaTextArea
            placeholder={t('merchant.fulltext.placeholder')}
            innerRef={inputRef}
          />
        </Row>
        <Row>
          <StyledButton onClick={submitFeedback}>
            <StyledButtonText>
              {t('misc.shareFeedback')}
            </StyledButtonText>
          </StyledButton>
        </Row>
      </Col>
    </MerchantLayout>
  );
}

export default Success;
