import * as React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  justify-content: center;  
`;

export const StyledInput = styled.textarea`
  resize: none;
  min-width: 0px;
  max-width: 350px;
  height: 250px;
  background: #F0F3F4;
  border: none;
  font-size: 24px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  
  @media only screen and (max-width: 400px) {
    font-size: 20px;
  }

  ::placeholder {
    font-family: 'Sombra Regular';
    color: #8D9299;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    @media only screen and (max-width: 400px) {
      font-size: 20px;
    }
  }
`;

function KarlaTextArea({
  innerRef,
  placeholder,
}) {
  return (
    <Root isLarge>
      <StyledInput
        ref={innerRef}
        placeholder={placeholder}
      />
    </Root>
  );
}

export default KarlaTextArea;
